import { listEarningRules } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/http';
import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { getTiersProgram } from '@wix/ambassador-loyalty-v1-tier/http';
import { Tier, TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export interface LoadDataResult {
  earningRules: LoyaltyEarningRule[];
  rewards: Reward[];
  tiersProgramSettings: TiersProgramSettings;
  tiers: Tier[];
  loyaltyProgram: LoyaltyProgram;
}

const WARMUP_DATA_KEY = 'loadData';

export async function loadData(
  flowAPI: ControllerFlowAPI,
  preloadedLoyaltyProgram?: LoyaltyProgram,
): Promise<LoadDataResult> {
  const { httpClient } = flowAPI;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { isSSR, isViewer } = flowAPI.environment;
  const useWarmupData = isViewer;
  const { warmupData } = wixCodeApi.window;

  // Reuse backend API responses from SSR on the client-side
  if (useWarmupData && !isSSR) {
    const dataFromSSR = warmupData.get(WARMUP_DATA_KEY);
    if (dataFromSSR) {
      return dataFromSSR;
    }
  }

  const loyaltyProgramPromise = !preloadedLoyaltyProgram ? httpClient.request(getLoyaltyProgram({})) : undefined;
  const rewardsPromise = httpClient.request(listRewards({}));
  const earningRulesPromise = httpClient.request(listEarningRules({}));
  const tiersProgramPromise = httpClient.request(getTiersProgram({}));

  const [rewardsResponse, earningRulesResponse, tiersProgramResponse, loyaltyProgramResponse] = await Promise.all([
    rewardsPromise,
    earningRulesPromise,
    tiersProgramPromise,
    loyaltyProgramPromise,
  ]);

  const rewards = rewardsResponse.data.rewards ?? [];
  const earningRules = earningRulesResponse.data.earningRules ?? [];
  const tiersProgramSettings = tiersProgramResponse?.data.programSettings ?? {};
  const tiers = tiersProgramResponse?.data.tiers ?? [];
  const loyaltyProgram = preloadedLoyaltyProgram || loyaltyProgramResponse?.data.loyaltyProgram!;

  const data = {
    earningRules,
    rewards,
    tiersProgramSettings,
    tiers,
    loyaltyProgram,
  };

  // Forward backend API responses from SSR to client-side
  if (useWarmupData && isSSR) {
    warmupData.set(WARMUP_DATA_KEY, data);
  }

  return data;
}
